import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Container } from '../blocks';
import SEO from '../components/seo';
export const query = graphql`
  query {
    main: file(name: { eq: "about-img" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About`}</h1>
    <SEO title="About" mdxType="SEO" />
    <GatsbyImage image={props.data.main.childImageSharp.gatsbyImageData} mdxType="GatsbyImage" />
    <p>{`I'm Ahmad Atallah. An Egyptian software engineer who's doing mostly frontend stuff. Also, interested in functional programming, and generative art. I like taking visual photos. Photos that I pass by in my daily life; every single day. Noam Chomsky once said: "When you walk around, you're talking to yourself. You can't stop. I mean, it takes a real act of will not talk to yourself, and what you're doing is thinking", and I find this is about the actual state of my mind all the time.`}</p>
    <p>{`Not to forget, I'm table tennis player `}{`🏓`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      